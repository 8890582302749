
import React, { useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useMounted from '../../hooks/useMounted';
import { IsModemAccess } from '../../helpers/Helper';
import CreatableSelect from 'react-select/creatable';

import SimpleReactValidator from 'simple-react-validator';
import { post } from '../../helpers/api_helper';
import { POST_GET_SERVICE_DETAIL, POST_ADD_EDIT_SERVICEL, POST_GET_WEB_PORTAL_SERVICE } from '../../helpers/url_helper';
import { toast } from 'react-toastify';

import Select from 'react-select';
import Loader from '../../components/Loader';

const Setting = (props) => {
    const navigate = useNavigate();

    const [isLoader, setLoader] = useState(0);

    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({
        serviceId: 0,
        name: '',
        smmSiteId: '',
        category: '',
        service: '',
        webPortalServicId: '',
        checkValidUrl : 0,
        services: [{
            smmOption: '',
            smmPanelId: '',
            smmPanelServiceId: '',
            smmPanelServiceInfo: '',
            smmServiceList: [],
            backup1SmmPanelId: '',
            backup1SmmPanelServiceId: '',
            backup1SmmPanelServiceInfo: '',
            backup1SmmServiceList: []
        }]
    });

    const [sitesList, SetSitesList] = useState([]);
    const [categoryList, SetCategoryList] = useState([]);
    const [serviceList, SetServiceList] = useState([]);
    const [smmServiceOptionList, SetSmmServiceOptionList] = useState([]);
    const [webServiceList, SetWebServiceList] = useState([]);
    const [smmPanelsList, SetSmmPanelsList] = useState([]);

    const { id } = useParams()

    useMounted(() => {
        async function get_detaile() {
            if (id) {
                if (!IsModemAccess('smm_service', 'edit')) {
                    toast.error('Does not permission this module');
                    navigate("/dashboard");
                }
            } else {
                if (!IsModemAccess('smm_service', 'add')) {
                    toast.error('Does not permission this module');
                    navigate("/dashboard");
                }
            }

            var res_data = await post(POST_GET_SERVICE_DETAIL, { 'serviceId': id });
            if (res_data.status) {
                SetSitesList(res_data.data.smm_sites_list)
                SetCategoryList(res_data.data.category_list)
                SetServiceList(res_data.data.service_list)
                SetSmmServiceOptionList(res_data.data.service_option_list)
                SetWebServiceList(res_data.data.web_portals_services_list)
                SetSmmPanelsList(res_data.data.smm_panels_list)
                if(res_data.data.services_detail){
                    setInputs(res_data.data.services_detail)
                }
            } else {
                toast.error(res_data.message);
                navigate("/");
            }
            setLoader(1)
        }

        get_detaile();
    }, [])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var form_value = form_inputs;
            if (form_value.services) {
                for (var service of form_value.services) {
                    delete service.smmServiceList;
                    delete service.backup1SmmServiceList;
                }
            }
            var res_data = await post(POST_ADD_EDIT_SERVICEL, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                navigate("/services");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        if (event.target.name == 'smmSiteId' || event.target.name == 'category' || event.target.name == 'service') {
            form_inputs[event.target.name] = event.target.value;
            getWebPortalServic()
        } else if(event.target.name == 'webPortalServicId'){
            if(!form_inputs.name){
                const result = webServiceList.find(({ portalServicId }) => portalServicId == event.target.value);
                form_inputs.name = (result)? result.name : '';
            }
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        } else if(event.target.name == 'checkValidUrl'){
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked)? 1 : 0 }));
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    // input text change handler
    const handleChangeService = (event, index) => {

        if (event.target.name == 'smmPanelId') {
            form_inputs.services[index][event.target.name] = event.target.value.smmPanelId;
            form_inputs.services[index]['smmPanelServiceId'] = '';
            form_inputs.services[index]['smmPanelServiceInfo'] = '';
            form_inputs.services[index]['smmServiceList'] = event.target.value.serviceList;
            setInputs({ ...form_inputs });
        } else if (event.target.name == 'smmPanelServiceId') {
            form_inputs.services[index][event.target.name] = event.target.value.service;
            form_inputs.services[index]['smmPanelServiceInfo'] = event.target.value;
            setInputs({ ...form_inputs });
        } else if (event.target.name == 'backup1SmmPanelId') {
            form_inputs.services[index][event.target.name] = event.target.value.smmPanelId;
            form_inputs.services[index]['backup1SmmPanelServiceId'] = '';
            form_inputs.services[index]['backup1SmmPanelServiceInfo'] = '';
            form_inputs.services[index]['backup1SmmServiceList'] = event.target.value.serviceList;
            setInputs({ ...form_inputs });
        } else if (event.target.name == 'backup1SmmPanelServiceId') {
            form_inputs.services[index][event.target.name] = event.target.value.service;
            form_inputs.services[index]['backup1SmmPanelServiceInfo'] = event.target.value;
            setInputs({ ...form_inputs });
        } else if (event.target.name == 'smmOption') {
            form_inputs.services[index][event.target.name] = event.target.value;
        } else {
            form_inputs.services[index][event.target.name] = event.target.value;
            setInputs({ ...form_inputs });
        }
    }

    const getWebPortalServic = async () => {
        var res_data = await post(POST_GET_WEB_PORTAL_SERVICE, { 'smmSiteId': form_inputs.smmSiteId, 'category': form_inputs.category, 'service': form_inputs.service });
        if (res_data.status) {
            SetWebServiceList(res_data.data.web_portals_services_list)
        }
    }

    const addRow = () => {
        form_inputs.services = (form_inputs.services) ? form_inputs.services : [];
        form_inputs.services.push({
            smmOption: '',
            smmPanelId: '',
            smmPanelServiceId: '',
            smmPanelServiceInfo: '',
            smmServiceList: [],
            backup1SmmPanelId: '',
            backup1SmmPanelServiceId: '',
            backup1SmmPanelServiceInfo: '',
            backup1SmmServiceList: []
        })
        setInputs({ ...form_inputs })
    }

    const removeRow = (index) => {
        const services = form_inputs.services;
        services.splice(index, 1);
        form_inputs.services = services
        setInputs({ ...form_inputs })
    }

     // Custom filter function
     const customFilter = (option, searchText) => {
        if (!searchText) return true;
        const regex = new RegExp(
            searchText
                .split(' ')
                .filter(Boolean)
                .map(term => `(?=.*${term})`) // Convert each word into a positive lookahead regex
                .join(''),
            'i' // 'i' for case-insensitive matching
        );
        return regex.test(option.label);
    };

    if (!isLoader) {
        return (<Loader />);
    }

    return (
        <>
            <div className='row'>
                <div className="col-md-12">
                    <div className="card mb-4">
                        <h5 className="card-header">SMM Service Mapping</h5>
                        <div className="card-body">
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='mb-3 col-sm-4'>
                                        <label className="form-label text-capitalize">Name *</label>
                                        <input name="name" value={form_inputs.name} onChange={handleInputChange} className="form-control" placeholder="Enter Name" type="text" />
                                        {simpleValidator.current.message('name', form_inputs.name, 'required')}
                                    </div>
                                    <div className='mb-3 col-sm-4'>
                                        <label className="form-label text-capitalize">Select Sites *</label>
                                        <select name='smmSiteId' onChange={handleInputChange} value={form_inputs.smmSiteId} className="form-control">
                                            <option value="">Select Site</option>
                                            {sitesList && sitesList.map((value, index) => (
                                                <option value={value.siteId} key={index}>{value.name}</option>
                                            ))}
                                        </select>
                                        {simpleValidator.current.message('sites', form_inputs.smmSiteId, 'required')}
                                    </div>
                                    <div className='mb-3 col-sm-4'>
                                        <label className="form-label text-capitalize">Select Category *</label>
                                        <select name='category' onChange={handleInputChange} value={form_inputs.category} className="form-control">
                                            <option value="">Select catogery</option>
                                            {categoryList && categoryList.map((value, index) => (
                                                <option value={value} key={index}>{value}</option>
                                            ))}
                                        </select>
                                        {simpleValidator.current.message('category', form_inputs.category, 'required')}
                                    </div>
                                    <div className='mb-3 col-sm-4'>
                                        <label className="form-label text-capitalize">Select Service *</label>
                                        <select name='service' onChange={handleInputChange} value={form_inputs.service} className="form-control">
                                            <option value="">Select Service</option>
                                            {serviceList && serviceList.map((value, index) => (
                                                <option value={value} key={index}>{value}</option>
                                            ))}
                                        </select>
                                        {simpleValidator.current.message('service', form_inputs.service, 'required')}
                                    </div>
                                    <div className='mb-3 col-sm-4'>
                                        <label className="form-label text-capitalize">Select Web Portal Servic *</label>
                                        <select name='webPortalServicId' onChange={handleInputChange} value={form_inputs.webPortalServicId} className="form-control">
                                            <option value="">Select Service</option>
                                            {webServiceList && webServiceList.map((value, index) => (
                                                <option value={value.portalServicId} key={index}>{value.name}</option>
                                            ))}
                                        </select>
                                        {simpleValidator.current.message('service', form_inputs.webPortalServicId, 'required')}
                                    </div>
                                    <div className='mb-3 col-sm-4'>
                                            <label className="form-label text-capitalize" htmlFor="checkValidUrl">Check Valide Url</label>
                                        <div className="form-check form-switch mb-2">
                                            <input className="form-check-input" defaultChecked={parseInt(form_inputs.checkValidUrl)} name='checkValidUrl' onChange={handleInputChange} type="checkbox" id="checkValidUrl" />
                                        </div>
                                    </div>

                                </div>
                                <hr />
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className='table-responsive-md'>
                                            <div className='text-right mb-3'>
                                                {(form_inputs.disableServiceInfo && form_inputs.disableServiceInfo.length > 0) &&
                                                    <button type='button' className="btn btn-sm text-danger mr-2" data-bs-toggle="modal" data-bs-target="#serviceModal"><i className="bx bx-error align-middle"></i></button> 
                                                }

                                                <button type='button' onClick={addRow} className='btn btn-primary btn-sm'>+ Add</button>
                                            </div>
                                            <table className='table table-bordered table-sm'>
                                                <tbody>
                                                    {form_inputs.services && form_inputs.services.map((service, key) => {
                                                        return <>
                                                            <tr key={`key1-${key}`}>
                                                                <td rowSpan={1}>
                                                                    {key + 1}
                                                                </td>
                                                                <td style={{ 'minWidth': '300px' }} rowSpan={1}>
                                                                    <label className="form-label text-capitalize">Options *</label>
                                                                    <CreatableSelect
                                                                        options={smmServiceOptionList}
                                                                        defaultValue={service.smmOption}
                                                                        isMulti
                                                                        onChange={(value) => { handleChangeService({ target: { name: 'smmOption', value: value } }, key) }}
                                                                    />
                                                                    {simpleValidator.current.message('options', service.smmOption, 'required')}
                                                                </td>
                                                                <td style={{ 'minWidth': '300px' }}>
                                                                    <label className="form-label text-capitalize">Select SMM Portal *</label>
                                                                    <Select
                                                                        getOptionLabel={option => option.name}
                                                                        getOptionValue={option => option.smmPanelId}
                                                                        defaultValue={smmPanelsList.find((a) => { return a.smmPanelId == service.smmPanelId })}
                                                                        onChange={(v) => { handleChangeService({ target: { name: "smmPanelId", value: v } }, key) }}
                                                                        options={smmPanelsList}
                                                                    />
                                                                    {simpleValidator.current.message('smm panel', service.smmPanelId, 'required')}
                                                                </td>
                                                                <td style={{ 'minWidth': '300px' }}>
                                                                    <label className="form-label text-capitalize">Select SMM Service *</label>
                                                                    <Select
                                                                        getOptionLabel={option => option.title}
                                                                        getOptionValue={option => option.service}
                                                                        defaultValue={service.smmPanelServiceInfo}
                                                                        onChange={(v) => { handleChangeService({ target: { name: "smmPanelServiceId", value: v } }, key) }}
                                                                        options={service.smmServiceList}
                                                                        filterOption={customFilter}
                                                                    />
                                                                    {simpleValidator.current.message('service', service.smmPanelServiceId, 'required')}
                                                                </td>
                                                                <td style={{ 'minWidth': '300px' }}>
                                                                    {service.smmPanelServiceInfo && <>
                                                                        <p className='mb-0'><small><b>ID :</b> {service.smmPanelServiceInfo.service}</small></p>
                                                                        <p className='mb-0'><small><b>Name :</b> {service.smmPanelServiceInfo.name}</small></p>
                                                                        <p className='mb-0'><small><b>Category :</b> {service.smmPanelServiceInfo.category}</small></p>
                                                                        <p className='mb-0'><small><b>Type :</b> {service.smmPanelServiceInfo.type} &nbsp; <b>Rate :</b> {service.smmPanelServiceInfo.rate} &nbsp; <b>Min :</b> {service.smmPanelServiceInfo.min} &nbsp; <b>Max :</b> {service.smmPanelServiceInfo.max}</small></p>
                                                                    </>}
                                                                </td>
                                                                <td rowSpan={1} width="60px" className='text-center'>
                                                                    <i onClick={removeRow.bind(this, key)} className="bx bx-trash-alt align-middle text-danger pointer"></i>
                                                                </td>
                                                            </tr>
                                                            {/* <tr key={`key2-${key}`}>
                                                                <td>
                                                                    <label className="form-label text-capitalize">Select Backup SMM Portal *</label>
                                                                    <Select
                                                                        getOptionLabel={option => option.name}
                                                                        getOptionValue={option => option.smmPanelId}
                                                                        defaultValue={smmPanelsList.find((a) => { return a.smmPanelId == service.backup1SmmPanelId })}
                                                                        onChange={(v) => { handleChangeService({ target: { name: "backup1SmmPanelId", value: v } },key) }}
                                                                        options={smmPanelsList}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <label className="form-label text-capitalize">Select Backup SMM Service *</label>
                                                                    <Select
                                                                        getOptionLabel={option => option.title}
                                                                        getOptionValue={option => option.service}
                                                                        defaultValue={service.backup1SmmPanelServiceId}
                                                                        onChange={(v) => { handleChangeService({ target: { name: "backup1SmmPanelServiceId", value: v } },key) }}
                                                                        options={service.backup1SmmServiceList}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {service.backup1SmmPanelServiceInfo && <>
                                                                        <p className='mb-0'><small><b>ID :</b> {service.backup1SmmPanelServiceInfo.service}</small></p>
                                                                        <p className='mb-0'><small><b>Name :</b> {service.backup1SmmPanelServiceInfo.name}</small></p>
                                                                        <p className='mb-0'><small><b>Category :</b> {service.backup1SmmPanelServiceInfo.category}</small></p>
                                                                        <p className='mb-0'><small><b>Type :</b> {service.backup1SmmPanelServiceInfo.type} &nbsp; <b>Rate :</b> {service.backup1SmmPanelServiceInfo.rate} &nbsp; <b>Min :</b> {service.backup1SmmPanelServiceInfo.min} &nbsp; <b>Max :</b> {service.backup1SmmPanelServiceInfo.max}</small></p>
                                                                    </>}
                                                                </td>
                                                            </tr> */}
                                                        </>
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>




                                <div className='text-center mt-4'>
                                    <button disabled={ButtonDisabled} className="btn btn-primary pt-2 pb-2">
                                        {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Save
                                    </button>
                                    <Link to="/services" className="btn btn-danger ms-3"> Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="serviceModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Service Disbale Info</h5>
                            <button type="button" className="btn btn-sm close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="bx bx-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <table className='table table-sm'>
                                <thead>
                                    <tr>
                                        <th>Service Id</th>
                                        <th>Service Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {form_inputs.disableServiceInfo && form_inputs.disableServiceInfo.map((service,key) => {
                                        return (<tr key={key}>
                                            <td>{service.service}</td>
                                            <td>{service.name}</td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Setting;